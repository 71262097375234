// Dependencies
import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
// Components
import matchProductId from '../helpers/matchProductId'
import Image from '../components/Image'
import Story from '../components/Story'

function useProductLinks(contentfulStory) {
	const data = useStaticQuery(graphql`
    {
      allShopifyProduct {
				nodes {
					title
					handle
					featuredImage {
						gatsbyImageData(layout: FULL_WIDTH)
					}
					images {
						gatsbyImageData(layout: FULL_WIDTH)
						medium: gatsbyImageData(layout: CONSTRAINED, width: 640)
						large: gatsbyImageData(layout: FULL_WIDTH)
					}
					variants {
						availableForSale
						storefrontId
						price
						compareAtPrice
					}
				}
			}
    }
  `)

	const productLinks = contentfulStory?.productLinks?.map((product) => {
		const shopifyProduct = data.allShopifyProduct.nodes.find((item) => {
			return item.variants.find(variant => matchProductId(variant.storefrontId, product.shopifyId))
		})
		return {
			...product,
			...shopifyProduct
		}
	})

	return productLinks
}

const ProjectStory = (props) => {

	const productLinks = useProductLinks(props)

	const story = {
		...props,
		productLinks
	}
	
	return (
		<div className="outerx4">
			<Story {...story} />
		</div>
	)
}

export default ProjectStory