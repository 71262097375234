// Dependencies
import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
// Hooks
import useVimeo from '../hooks/useVimeo'

const Vimeo = ({ url }) => {

	const [ref] = useVimeo({ url: url })

	return (
		<div className="project-video__embed" ref={ref} />
	)
}

const Video = ({ src }) => {

	const [willPlay, set] = useState(false)

	const handlePlay = (event) => {
		set(true)
	}

	const style = {
		opacity: willPlay ? 1 : 0
	}

	return (
		<video src={src} autoPlay muted loop playsInline controls={false} onPlaying={handlePlay} style={style} />
	)
}

const ProjectVideo = (props) => {

	const { vimeoUrl, mp4 } = props
	// console.log(props)
	
	return (
		<div className="outerx4 project-video">
			{vimeoUrl ? (
				<Vimeo url={vimeoUrl} />
			) : (mp4?.file?.url) ? (
				<Video src={mp4.file.url} />
			) : null}
		</div>
	)
}

export default ProjectVideo