// Dependencies
import React, { useRef, useState, useEffect } from "react"
import Player from '@vimeo/player';

const DEFAULT_CONFIG = {
  url: 'https://vimeo.com/321249877',
  byline: false,
  color: "#FFF",
  portrait: false,
  title: false,
  background: 1
}

export default function useVimeo(config) {

  // UX
  const ref = useRef();
  const [player, setPlayer] = useState(null);
  const [ready, setReady] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(0.56);

  useEffect(() => {
    setPlayer(
      new Player(ref.current, { ...DEFAULT_CONFIG, ...config })
    )
  }, [])

  useEffect(() => {
    let isInitialized;
    if (player) {
      isInitialized = true
      Promise.all([player.getVideoWidth(), player.getVideoHeight()])
        .then((dimensions) => {
          if (isInitialized && player) {
            setAspectRatio(dimensions[1] / dimensions[0]);
            setReady(true)
          }
        });
    }
    return () => isInitialized = false
  }, [player])

  // UI
  // ...

  return [ref, ready, aspectRatio]
}
