// Dependencies
import React, { useState, useRef, useEffect } from "react"
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
import { useInView } from 'react-intersection-observer'
// Hooks & Helpers
import useRandomPosition from "../hooks/useRandomPosition"
// Wrappers
// import Layout from "../wrappers/Layout"
// Components
import Image from "../components/Image"
import Note from "../components/Note"
import ProductThumbnail from "../components/ProductThumbnail"
import RichText from "../components/RichText"
import Video from "../components/Video"


const Caption = ({ content }) => (
	<div className="mt p3">
		<RichText content={content} />
	</div>
)

const Product = ({ product, index }) => {

	const timer = useRef()
	const startDesktop = useRandomPosition(400, 100)
	const startMobile = useRandomPosition(50, 50)
	const [pos, setPos] = useState({x: startDesktop.x, y: startDesktop.y})
	const startTransform = `translate(${pos.x + 20}%, ${pos.y + 20}%)`
	const endTransform = `translate(${pos.x}%, ${pos.y}%)`

	const [show, set] = useState(false)

	useEffect(() => {
		// Yuck...
		if ((window.innerWidth >= 768) && (window.innerWidth > window.innerHeight)) {
			setPos(startDesktop)
		} else {
			setPos(startMobile)
		}

		timer.current = setTimeout(() => {
			set(true)
		}, index * 250)
		return () => clearTimeout(timer.current)
	}, [])

	const style = {
		transform: show ? endTransform : startTransform,
		opacity: show ? 1 : 0,
		transition: `opacity 0.75s, transform 0.75s`
	}

	return (
		<div className="story__product" style={style}>
			<ProductThumbnail product={product} />
		</div>
		
	)
}

const StoryProducts = ({ products }) => {

	return (
		<div className="story__products">
			{products.map((entry, index) => (
				<Product product={entry} index={index} key={entry.shopifyId} />
			))}
		</div>
	)
}

const ProductLink = ({ onClick, inView }) => {

	return (
		<button className={`story__products__toggle p3 ${inView ? 'visible' : ''}`} onClick={onClick}>View product(s)</button>
	)
}

const Story = ({ contentful_id, index, image, note, video, caption, productLinks }) => {

	// UX
	const [showProducts, setShowProducts] = useState(false)

	const { ref, inView } = useInView({
    threshold: 0.33
  });

	// UI
	const toggle = () => {
		setShowProducts(o => !o)
	}

	// Helpers
	const anchor = `story${contentful_id}`

	return (
		image ? (
			<div id={anchor} className="gallery__story" ref={ref}>
				{productLinks && <ProductLink onClick={toggle} inView={inView} />}
				<Image image={image.gatsbyImageData} />
				{caption && <Caption content={caption} />}
				{showProducts && productLinks && <StoryProducts products={productLinks} showProducts={showProducts} />}
			</div>
		) : note ? (
			<div id={anchor} className="gallery__story">
				<Note content={note} />
			</div>
		) : video ? (
			<div id={anchor} className="gallery__story">
				<Video src={video.mp4?.file?.url} />
				{caption && <Caption content={caption} />}
			</div>
		) : null
	)
}

export default Story
