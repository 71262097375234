// Dependencies
import React from 'react'
import { Link, graphql } from 'gatsby'
// Components
import RichText from "../components/RichText"

import backgroundImage from "../images/note-hole.svg"

const Note = ({ content, onClick, chapterGallery }) => {
	
	return (
		<div className="note" style={{
			backgroundImage: `url(${backgroundImage})`
		}}>
			<div className="note__inner innerx4 guttersx4 p1">
				<RichText content={content} />
				{chapterGallery && (
					<button className="h3 upcase mtx4" onClick={onClick}>Continue reading</button>
				)}
			</div>
		</div>
	)
}

export default Note