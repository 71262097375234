// Dependencies
import React, { useEffect, useState, useRef } from "react"
import { motion, useDragControls, useMotionValue } from 'framer-motion'
// import { useInView } from 'react-intersection-observer'
import Player from '@vimeo/player';

const VideoFullscreenButton = ({ player }) => {

	const handleClick = () => {
		player.requestFullscreen().then(() => {
			player.play()
		})
	}

	return (
		<button
			className="video-fullscreen-btn"
			onClick={handleClick}
			aria-label="Fullscreen"
		>
			<svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10 4.37114e-07H4.37114e-07V10M0 18L4.37114e-07 28H10M19 28H29V18M29 10V4.37114e-07L19 0" stroke="white" strokeWidth="3"/>
			</svg>
		</button>
	)
}

const AspectRatioBox = ({ size, ratio }) => (
	<svg 
		className="aspect-box"
		width={size * ratio} 
		height={size} 
		viewBox={`0 0 ${size * ratio} ${size}`}
	>
		<rect 
			width={size * ratio} 
			height={size} 
			fill={null} 
		/>
	</svg>
)

const PlayPauseButton = ({ isPlaying, isLoading, onClick }) => (
	<button
		className="video-play-btn"
		onClick={onClick}
	>
		{isLoading && isPlaying ? (
			<svg className="loading" width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M42 9.82101C46.1286 8.6352 50.4901 8 55 8C80.9574 8 102 29.0426 102 55C102 80.9574 80.9574 102 55 102C29.0426 102 8 80.9574 8 55C8 42.6781 12.7417 31.4636 20.5 23.0818" stroke="white" strokeWidth="2"/>
			</svg>
		) : !isPlaying ? (
			<svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10.5 8.19028L104.771 55.5L10.5 102.81L10.5 8.19028Z" stroke="white" strokeWidth="2"/>
			</svg>
		) : (
			<svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M47 8H49V102H47V8Z" fill="white"/>
				<path d="M61 8H63V102H61V8Z" fill="white"/>
			</svg>
		)}
	</button>
)

const VideoProgress = ({ player, togglePlay }) => {

	const dragBar = useRef()
	const dragHead = useRef()
	const dragControls = useDragControls()
	const dragLeft = useMotionValue(0)
	
	const handleDragEnd = (e, info) => {
		const barLeft = dragBar.current.getBoundingClientRect().left
		const headLeft = dragHead.current.getBoundingClientRect().left
		const percent = (headLeft - barLeft) / dragBar.current.offsetWidth
		const cleanPercent = Math.min(Math.max(percent, 0), 1)
		player.getDuration().then(duration => {
			player.setCurrentTime(duration * cleanPercent)
			// togglePlay()
			player.play()
		})
	}
	
	const handleTimeUpdate = (e) => {
		dragLeft.set(dragBar.current.offsetWidth * e.percent)
	}

	useEffect(() => {
		if (player) {
			player.off('timeupdate', handleTimeUpdate)
			player.on('timeupdate', handleTimeUpdate)
		}
	}, [player])

	return (
		<div className="video-progress">
			<div className="shadow-bar" ref={dragBar}>
				<div className="shadow-bar-inner">
					<div className="bar" />
					<motion.div 
						drag="x" 
						dragConstraints={dragBar}
						dragMomentum={false}
						dragControls={dragControls}
						onDragStart={() => player.pause()}
						onDragEnd={handleDragEnd}
						className="shadow-head" 
						whileHover={{ scale: 1.2 }}
						style={{
							x: dragLeft
						}}
						ref={dragHead}
					>
						<div className="shadow-head-inner">
							<div className="head" />
						</div>
					</motion.div>
				</div>
			</div>
		</div>
	)
}

const VimeoPlayer = ({ 
	vimeoUrl,
  className = "vimeo-player",
  aspectRatio = 4 / 3,
  placeholderSize = 1080,
}) => {

	const ref = useRef()
	const focusTimer = useRef()
	const [player, setPlayer] = useState(null)
	const [isLoading, setIsLoading]	= useState(true)
	const [isPlaying, setPlaying] = useState(false)
	const [hasPlayed, setPlayed] = useState(false)
	const [isFullscreen, setFullscreen] = useState(false)
	const [focus, setFocus] = useState(false)

	useEffect(() => {
		const player = new Player(ref.current, {
			url: vimeoUrl,
			autoplay: false,
			byline: false,
			controls: false,
			loop: true,
			muted: false,
			playsinline: true,
			portrait: false,
			title: false,
		})
		setPlayer(player)

		player.on('play', handlePlay)
		player.on('bufferstart', handleBufferStart)
		player.on('bufferend', handleBufferEnd)
		player.on('fullscreenchange', handleFullscreen)

		return () => player.destroy()
	}, [])

	const handleFullscreen = (event) => {
		if (event.fullscreen) {
			setFullscreen(true)
		}
	}

	const handlePlay = () => {
		setPlaying(true)
		setPlayed(true)
	}

	const handleBufferStart = () => {
		setIsLoading(true)
	}

	const handleBufferEnd = () => {
		setIsLoading(false)
	}

	const togglePlay = () => {
		if (player) {
			if (isPlaying) {
				player.pause()
				setPlaying(false)
			} else {
				player.play()
				setPlaying(true)
			}
		}
	}

	const handleFocus = (delay) => {
		if (isPlaying) {
			clearTimeout(focusTimer.current)
			setFocus(true)
			focusTimer.current = setTimeout(() => {
				setFocus(false)
			}, delay)
		} else {
			setFocus(true)
		}
	}

	return (
		<div 
      className={className}
			onMouseMove={() => handleFocus(2000)}
			onTouchStart={() => handleFocus(6000)}
		>
			<AspectRatioBox 
				size={placeholderSize} 
				ratio={aspectRatio} 
			/>
			<div className="iframe-wrapper" style={{
				opacity: isPlaying ? 1 : 1,
			}} ref={ref} />
			<motion.div 
				className="video-controls"
				animate={{
					opacity: (!hasPlayed || focus) ? 1 : 0,
				}}
			>
				<PlayPauseButton
					isLoading={isLoading}
					isPlaying={isPlaying}
					onClick={togglePlay}
				/>
				<VideoProgress player={player} togglePlay={togglePlay} />
				<VideoFullscreenButton player={player} />
			</motion.div>
		</div>
	)
}

export default VimeoPlayer