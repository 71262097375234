// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// Hooks & Helpers
// ....
// Components
import Image from '../components/Image'
import ProjectStory from '../components/ProjectStory'
import ProjectVideo from '../components/ProjectVideo'

// MARKS
const Bold = ({ children }) => <strong>{children}</strong>
const Italic = ({ children }) => <i>{children}</i>

// BLOCKS
const P = ({ children }) => <p>{children}</p>
const H1 = ({ children }) => <h1>{children}</h1>
const H2 = ({ children }) => <h2>{children}</h2>
const H3 = ({ children }) => <h3>{children}</h3>
const H4 = ({ children }) => <h4>{children}</h4>

// [INLINES.HYPERLINK]
const Hyperlink = ({ data, children }) => {
	// console.log(data);
	return (
		<a href={data.uri} target="_blank" rel="noopener noreferrer">{children}</a>
	)
}

// [INLINES.ASSET_HYPERLINK]
const DownloadLink = ({ data, children }) => {
	const file = data.target && data.target.file;
	return (
		file ? (
			<a href={file.url} download={file.title} target="_blank">{children}</a>
		) : (
			<span className="c-constraints">[Asset missing]</span>
		)
	)
}

// [INLINES.ENTRY_HYPERLINK]
const InlineEntry = ({ data, children }) => {
	
	const type = data?.target?.__typename

	if (!type) {
		return (
			<span className="debug">{children}</span>
		)
	}

  if (type === 'ContentfulPage') {
    return (
			<Link to={`/info/${data.target.slug}`}>{children}</Link>
		)
  }
  
	return (
		<span className="debug-2">{type}</span>
	)
}

// [BLOCKS.EMBEDDED_ENTRY]
const EmbeddedEntry = ({ data }) => {

	const type = data && data.target && data.target.__typename

	if (!type) {
		return (
			<div className="debug">[BLOCKS.EMBEDDED_ENTRY] Missing data and/or __typename</div>
		)
	}

	if (type === 'ContentfulVideo') {
		return (
			<ProjectVideo {...data.target} />
		)
	}
	if (type === 'ContentfulStory') {
		return (
			<ProjectStory {...data.target} />
		)
	}

  return (
		<div className="debug">{type}</div>
	)
}

// [BLOCKS.EMBEDDED_ASSET]
const EmbeddedAsset = ({ data }) => {

	const asset = data?.target

	return (
		<div className="outerx4">
			{asset?.gatsbyImageData && <Image image={asset.gatsbyImageData} />}
		</div>
	)
}

const ProjectCopy = ({ children }) => (
	<div className="p2 bookish text-columns outerx4">
		{children}
	</div>
)

const options = (props) => {

	return {
		renderMark: {
			[MARKS.BOLD]: text => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: text => <Italic>{text}</Italic>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (_node, children) => <P>{children}</P>,
			[BLOCKS.HEADING_1]: (_node, children) => <H1>{children}</H1>,
			[BLOCKS.HEADING_2]: (_node, children) => <H2>{children}</H2>,
			[BLOCKS.HEADING_3]: (_node, children) => <H3>{children}</H3>,
			[BLOCKS.HEADING_4]: (_node, children) => <H4>{children}</H4>,
			[BLOCKS.QUOTE]: (node, children) => <ProjectCopy>{children}</ProjectCopy>,
			[BLOCKS.EMBEDDED_ENTRY]: (node, children) => <EmbeddedEntry {...node}>{children}</EmbeddedEntry>,
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => <EmbeddedAsset {...node}>{children}</EmbeddedAsset>,
			[INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node}>{children}</Hyperlink>,
			[INLINES.ASSET_HYPERLINK]: (node, children) => <DownloadLink {...node}>{children}</DownloadLink>,
			[INLINES.ENTRY_HYPERLINK]: (node, children) => <InlineEntry {...node}>{children}</InlineEntry>
		},
		renderText: text => text.split('\n')
			.reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])
	}
}

const RichText = ({ content, props }) => {
	return content && (
		renderRichText(content, options(props))
	)
}

RichText.propTypes = {
  content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array
	})
}

export default RichText

// export const ContentfulRichTextFragment = graphql`
// 	fragment ContentfulRichTextFragment on ContentfulRichTextNode {
// 		raw
// 		references {
// 			... on ContentfulAsset {
// 				__typename
// 				contentful_id
// 				title
// 				file {
// 					url
// 				}
// 				gatsbyImageData(
// 					layout: FULL_WIDTH
// 				)
// 			}
// 		}
// 	}
// `